(function (doc, win) {
  var docEL = document.documentElement,
    resizeEvt = "orientationchange" in window ? "orientationchange" : "resize",
    recalc = function () {
      // 鑾峰彇褰撳墠璁惧鐨勫搴r
      var clientWidth = docEL.clientWidth;
      if (!clientWidth) return;
      if (clientWidth >= 750) {
        //瀹藉害澶т簬750锛屾牴鍏冪礌瀛椾綋澶у皬涓嶈兘瓒呰繃100px锛堜笉鑳藉ぇ浜庤璁＄鐨勬渶澶у搴︼級
        docEL.style.fontSize = "100px"; //璁剧疆鏍瑰厓绱犲ぇ灏廫r
      } else {
        docEL.style.fontSize = (clientWidth / 750) * 100 + "px";
      }
    };
  if (!doc.addEventListener) return;
  //娣诲姞绐楀彛鍙樺姩鐩戝惉
  win.addEventListener(resizeEvt, recalc, false);
  //dom鍔犺浇鐨勬椂鍊欐墽琛屼竴娆r
  doc.addEventListener("DOMContentLoaded", recalc, false);
})(document, window);